import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

import './instagram.styles.scss';

const InstagramMedia = () => {
  const [instaUrl, setInstaUrl] = useState([]);
  const [httpStatus, setHttpStatus] = useState(200);

  useEffect(() => {
    getInsta();
  }, []);

  const getInsta = async () => {
    try {
      const res = await axios.get(
        `https://graph.instagram.com/me/media?fields=media_url,permalink&access_token=${process.env.REACT_APP_ACCESS_TOKEN}`
      );

      setInstaUrl(res.data.data);
    } catch (error) {
      setHttpStatus(error.response.status);
    }
  };

  const insta = <FontAwesomeIcon className="fa" icon={faInstagram} size="2x" />;

  return (
    <div className="insta-container">
      {(() => {
        if (httpStatus !== 200) {
          return <span> {httpStatus} ... Something went wrong D: </span>;
        } else {
          return (
            <div className="insta-grid">
              {instaUrl.slice(0, 8).map((m) => (
                <div key={m.id} className="insta-link">
                  <a
                    href={m.permalink}
                    target="_blank"
                    rel="noreferrer noopner"
                  >
                    <div className="insta-link-overlay">{insta}</div>
                    <img
                      src={m.media_url}
                      className="insta-img"
                      key={m.id}
                      alt={m.id}
                    />
                  </a>
                </div>
              ))}
            </div>
          );
        }
      })()}
    </div>
  );
};

export default InstagramMedia;
